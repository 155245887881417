import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Tabs, Col, Row } from "antd";
import { Trades } from "./components/Trades/Trades";
import { Transactions } from "./components/Transactions/Transactions";
import { NetBuyRewards } from "./components/Rewards/NetBuyRewards";
import { LongProfitRewards } from "./components/Rewards/LongProfitRewards";
import { SelectPeriod } from "../../components/SelectPeriod/SelectPeriod";
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/authSlice';
import styles from "./MainPage.module.css";
import { getMyInfo, updateAddress } from "services/api";
import { AddWalletAddressModal } from "../../modals/AddWalletAddressModal/AddWalletAddressModal";
import { LoginModal } from "../../modals/LoginModal/LoginModal";

const { TabPane } = Tabs;


export const MainPage = () => {
  let auth = useSelector(selectAuth);

  const urlParams = useParams();
  const history = useHistory();
  const { tab } = urlParams;
  const [currentTab, setCurrentTab] = useState(undefined);
  const [tabInitialized, setTabInitialized] = useState(false);

  let [data, setData] = useState();
  let [counter, setCounter] = useState(0);
  const [walletModalVisible, setWalletModalVisibility] = useState(false);

  useEffect(() => {
    if (tabInitialized && tab !== currentTab) {
      setCurrentTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (!tabInitialized) {
      setCurrentTab(tab || "rewards");
      setTabInitialized(true);
    }
  }, [tabInitialized]);

  useEffect(() => {
    if (tabInitialized && currentTab) {
      history.replace(`/me/${currentTab || ""}`);
    }
  }, [currentTab]);


  useEffect(() => {
    if (!auth.id) {
      setData(undefined);
      return;
    }
    const updateMyInfo = async () => {
      const resp = await getMyInfo(auth);
      if (resp.status === 'success') {
        setData(resp.data);
      }
    };
    updateMyInfo();
  }, [counter, auth.id]);

  const saveAddress = async (new_address) => {
    setWalletModalVisibility(false);
    if (new_address === data.address)
      return console.log("address is unchanged");
    const resp = await updateAddress(auth, new_address);
    if (resp.status === 'success') {
      data.address = new_address;
      setData(data);
      setCounter(counter + 1);
      setTimeout(() => setCounter(counter + 1), 10 * 60 * 1000);
    }
  };

  return (
    <div className={styles.container}>
      <Helmet title="Obyte Trading Rewards - My data" />

      <LoginModal />

      Address for payouts: {data && data.address} {data && <span onClick={setWalletModalVisibility} className={styles.addWallet}>{data.address ? "edit" : "add your wallet address"}</span>} {data && !data.address && <span>(we'll start collecting data once you add your address)</span>}
      
      <AddWalletAddressModal
        visible={walletModalVisible}
        initialAddress={data && data.address}
        title={data && data.address ? "Edit wallet" : "Add wallet"}
        hide={() => setWalletModalVisibility(false)}
        saveAddress={saveAddress}
        onCancel={() => setWalletModalVisibility(false)}
      />

      <SelectPeriod />

      <Tabs
        activeKey={currentTab}
        onChange={(key) => setCurrentTab(key)}
        animated={false}
      >
        <TabPane tab="Rewards" key="rewards">
          {data && <Row style={{ marginTop: 20 }}>
            <Col md={{ span: 10 }} xs={{ span: 24 }}>
              <NetBuyRewards trades={data.trades} transactions={data.transactions} creation_date={data.creation_date} net_buys={data.net_buys} />
            </Col>
            <Col md={{ span: 10, offset: 4 }} xs={{ span: 24 }}>
              <LongProfitRewards trades={data.trades} unrealized_long_profit={data.unrealized_long_profit} long_profits={data.long_profits} />
            </Col>
          </Row>}
        </TabPane>

        <TabPane tab="Trades" key="trades">
          {data && <Trades trades={data.trades} unrealized_long_profit={data.unrealized_long_profit} />}
        </TabPane>

        <TabPane tab="Deposits/Withdrawals" key="transactions">
          {data && <Transactions transactions={data.transactions} />}
        </TabPane>
      </Tabs>

    </div>
  );
};
