import React from "react";
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Divider } from "antd";
import { selectPeriods } from "store/periodsSlice";
import { round } from "utils";


const { Title } = Typography;

export const NetBuyRewards = ({ creation_date, trades, transactions, net_buys }) => {

  let { selectedPeriodId, list } = useSelector(selectPeriods);
  if (!list || !selectedPeriodId)
    return null;
  const period = list.find(p => p.period_id === selectedPeriodId);
  const current_nb = net_buys.find(nb => nb.period_id === selectedPeriodId);

  let nb_start_date;
  // find the last period when we had net buys
  for (let nb of net_buys) {
    if (nb.period_id >= selectedPeriodId)
      break;
    nb_start_date = list.find(p => p.period_id === nb.period_id).end_date.substr(0, 10);
  }
  if (!nb_start_date) // find the period when we joined
    nb_start_date = list.find(p => creation_date >= p.start_date && creation_date < p.end_date).start_date.substr(0, 10);

  const filtered_trades = trades.filter(t => t.trade_date >= nb_start_date && t.trade_date < period.end_date && t.type === 'trade');
  const filtered_transactions = transactions.filter(t => t.transaction_date >= nb_start_date && t.transaction_date < period.end_date);

  const total_buys = filtered_trades.filter(t => t.side === 'buy').reduce((s, t) => s + t.amount, 0);
  const total_sells = filtered_trades.filter(t => t.side === 'sell').reduce((s, t) => s + t.amount, 0);
  const total_deposits = filtered_transactions.filter(t => t.type === 'deposit').reduce((s, t) => s + t.amount, 0);
  const total_withdrawals = filtered_transactions.filter(t => t.type === 'withdrawal').reduce((s, t) => s + t.amount, 0);
  const net_withdrawals = Math.max(0, total_withdrawals - total_deposits);
  const net_buys_amount = total_buys - total_sells - net_withdrawals;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Title level={3}>Net Buy Rewards</Title>
      </div>

      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Total buys since {nb_start_date}</Col>
        <Col xs={{ span: 12 }}>{round(total_buys)} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Total sells since {nb_start_date}</Col>
        <Col xs={{ span: 12 }}>{round(total_sells)} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Total withdrawals / deposits / net withdrawals since {nb_start_date}</Col>
        <Col xs={{ span: 12 }}>{round(total_withdrawals)} / {round(total_deposits)} / {round(net_withdrawals)} GBYTE</Col>
      </Row>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Net buys since {nb_start_date}</Col>
        <Col xs={{ span: 12 }}>{round(net_buys_amount)} GBYTE</Col>
      </Row>

      <Row gutter="16" style={{ paddingTop: 5, marginTop: 30 }}>
        <Col xs={{ span: 12 }}>All net buys of all participants</Col>
        <Col xs={{ span: 12 }}>{period.total_net_buys} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Total reward</Col>
        <Col xs={{ span: 12 }}>{period.net_buys_scaling_factor ? round(period.total_net_buys * period.net_buys_scaling_factor, 2) : 10} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Your net buys</Col>
        <Col xs={{ span: 12 }}>{current_nb ? current_nb.net_buy : 0} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5, marginBottom: 40 }}>
        <Col xs={{ span: 12 }}>Your reward</Col>
        <Col xs={{ span: 12 }}><span style={{ fontWeight: 'bold' }}>{current_nb ? current_nb.reward / 1e9 : 0} GBYTE</span> {current_nb && current_nb.payment_unit && <a href={"https://explorer.obyte.org/#" + current_nb.payment_unit} target="_blank" rel="noopener">payment unit</a>}</Col>
      </Row>
    </>
  );
};
