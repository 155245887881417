import React from "react";
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Divider } from "antd";
import { selectPeriods } from "store/periodsSlice";
import { round } from "utils";


const { Title } = Typography;

export const LongProfitRewards = ({ trades, long_profits, unrealized_long_profit }) => {
  let { selectedPeriodId, list } = useSelector(selectPeriods);

  if (!list || !selectedPeriodId)
    return null;
  const period = list.find(p => p.period_id === selectedPeriodId);
  const bOngoingPeriod = selectedPeriodId === list[0].period_id;

  const current_lp = long_profits.find(lp => lp.period_id === selectedPeriodId);

  const filtered_trades = trades.filter(t => t.trade_date >= period.start_date && t.trade_date < period.end_date);
  const realized_long_profit = filtered_trades.filter(t => t.side === 'sell' && t.profit > 0).reduce((s, t) => s + t.profit, 0);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Title level={3}>Rewards for profit from long positions</Title>
      </div>

      {!bOngoingPeriod && <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Total profit from long trades</Col>
        <Col xs={{ span: 12 }}>{round(realized_long_profit, 8)} BTC</Col>
      </Row>}
      {bOngoingPeriod && <div>
        <Row gutter="16" style={{ paddingTop: 5 }}>
          <Col xs={{ span: 12 }}>Realized profit from long trades</Col>
          <Col xs={{ span: 12 }}>{round(realized_long_profit, 8)} BTC</Col>
        </Row>
        <Row gutter="16" style={{ paddingTop: 5 }}>
          <Col xs={{ span: 12 }}>Unrealized profit from long trades</Col>
          <Col xs={{ span: 12 }}>{round(unrealized_long_profit || 0, 8)} BTC</Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row gutter="16" style={{ paddingTop: 5 }}>
          <Col xs={{ span: 12 }}>Total estimation of profit from long trades</Col>
          <Col xs={{ span: 12 }}>{round(realized_long_profit + Math.max(unrealized_long_profit, 0), 8)} BTC</Col>
        </Row>
      </div>}

      <Row gutter="16" style={{ paddingTop: 5, marginTop: 30 }}>
        <Col xs={{ span: 12 }}>Total profit from long positions of all participants</Col>
        <Col xs={{ span: 12 }}>{round(period.total_long_profits, 8)} BTC</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Max total reward</Col>
        <Col xs={{ span: 12 }}>{period.period_id <= 123 ? 100 : 10} GBYTE</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Your profit from long positions</Col>
        <Col xs={{ span: 12 }}>{current_lp ? round(current_lp.long_profit, 8) : 0} BTC</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5 }}>
        <Col xs={{ span: 12 }}>Your reward</Col>
        <Col xs={{ span: 12 }}>{current_lp ? round(current_lp.long_profit * period.long_profits_scaling_factor, 8) : 0} BTC</Col>
      </Row>
      <Row gutter="16" style={{ paddingTop: 5, marginBottom: 40 }}>
        <Col xs={{ span: 12 }}>Your reward in GBYTE</Col>
        <Col xs={{ span: 12 }}><span style={{ fontWeight: 'bold' }}>{current_lp ? current_lp.reward / 1e9 : 0} GBYTE</span> {current_lp && current_lp.payment_unit && <a href={"https://explorer.obyte.org/#" + current_lp.payment_unit} target="_blank" rel="noopener">payment unit</a>}</Col>
      </Row>
    </>
  );
};
