import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

import logoImg from "../../img/logo.svg";
import styles from "./Header.module.css";

export const Header = () => {
  return (
    <div className={styles.header}>
      <img src={logoImg} className={styles.logo} alt="" />
      <h1 className={styles.mainTitle}>Obyte Trading Rewards</h1>
      <h2 className={styles.subTitle}>Get rewarded for trading GBYTE</h2>
      <h3 className={styles.desc}><a target="_blank" rel="noopener" href="https://obyte.org/">Obyte</a> is a powerful DAG-based dapp platform. <br /> It has a great product but little trading activity around its native token — GBYTE. <br />Let's fix that, and make money in the process.</h3>
      <Link className={styles.btnJoin} to="/me" onClick={() => ReactGA.event({ category: 'join', action: 'header click join' })}>
        Join now
      </Link>
    </div>
  );
}