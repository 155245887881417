import React from "react";
import { useSelector } from 'react-redux';
import { Table, Typography } from "antd";
import { selectPeriods } from "store/periodsSlice";
import { round } from "utils";

const { Title } = Typography;

export const Transactions = ({ transactions }) => {

  let { selectedPeriodId, list } = useSelector(selectPeriods);
  if (!list || !selectedPeriodId)
    return null;
  const period = list.find(p => p.period_id === selectedPeriodId);
  const filtered_transactions = transactions.filter(t => t.transaction_date >= period.start_date && t.transaction_date < period.end_date);

  const total_deposits = filtered_transactions.filter(t => t.type === 'deposit').reduce((s, t) => s + t.amount, 0);
  const total_withdrawals = filtered_transactions.filter(t => t.type === 'withdrawal').reduce((s, t) => s + t.amount, 0);
  const net_withdrawals = Math.max(0, total_withdrawals - total_deposits);

  const columns = [
    {
      title: "UTC Date",
      dataIndex: "transaction_date",
      key: "ts",
      showSorterTooltip: true,
      sorter: (t1, t2) => t1.transaction_date < t2.transaction_date ? -1 : 1,
      //defaultSortOrder: 'descend',
      render: (value) => {
        return value;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value) => {
        return <span style={{ color: value === 'deposit' ? 'green' : 'red' }}>{value}</span>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => {
        return value;
      },
    },
    {
      title: "Tx ID",
      dataIndex: "txid",
      key: "txid",
      render: (value) => {
        return <a href={"https://explorer.obyte.org/#" + value} target="_blank" rel="noopener">{value}</a>;
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Title level={3}>Deposits / Withdrawals</Title>
      </div>

      <Table
        dataSource={filtered_transactions}
        rowKey="transaction_id"
        columns={columns}
        locale={{
          emptyText: "No transactions",
        }}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
      />

      <div style={{ marginTop: '20px' }}>
        <div>Total withdrawals: {round(total_withdrawals)} GBYTE.</div>
        <div>Total deposits: {round(total_deposits)} GBYTE.</div>
        <div>Net withdrawals: {round(net_withdrawals)} GBYTE.</div>
      </div>

    </>
  );
};
