import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import './index.css';
import getStore from "./store";

import { Provider } from 'react-redux';
import ReactGA from "react-ga";
import * as serviceWorker from './serviceWorker';
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./AppRouter";
import { HelmetProvider } from 'react-helmet-async';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

export const { store, persistor } = getStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
