import React from "react";
import { Modal } from "antd";
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/authSlice';
import historyInstance from '../../historyInstance';

export const LoginModal = ({ title }) => {
  let auth = useSelector(selectAuth);

  const handleCancel = () => {
		historyInstance.replace("/");
  };

  return (
    <Modal
      visible={!auth || !auth.id}
      title={title || "Log in"}
      maskClosable={false}
      width={400}
      style={{ zIndex: -1 }}
      onCancel={handleCancel}
			footer={null}
    >
      <LoginForm/>
    </Modal>
  );
};
