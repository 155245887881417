import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    setAuth: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //  state.auth = action.payload;
      return action.payload;
      //Object.assign(state, action.payload)
    },
    // log off
    resetAuth: (state, action) => {
      return {};
    }
  },
});

export const { setAuth, resetAuth } = authSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.auth.value)`
export const selectAuth = state => state.auth;

export default authSlice.reducer;
