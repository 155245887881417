import React, { useEffect, useState } from "react";
import { Typography, Tabs } from "antd";
import { Helmet } from "react-helmet-async";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import styles from "./HowToJoinPage.module.css";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;


export const HowToJoinPage = () => {

  const urlParams = useParams();
  const history = useHistory();
  const { tab } = urlParams;
  const [currentTab, setCurrentTab] = useState(tab || "bittrex");

  useEffect(() => {
    if (tab !== currentTab) {
      history.replace(`/how-to-join/${currentTab || ""}`);
    }
  }, [currentTab]);

  return (
    <div>
      <Helmet title="Obyte Tading Rewards - How to join" />
      <div className={styles.howToJoin}>
        <Title level={1}>How to join</Title>
        <Paragraph>To participate in the rewards programs, you need to join using your Bittrex read-only API key. We'll use this key to track your trades, deposits, and withdrawals, but since the key is read-only, we won't be able to trade or withdraw funds on your behalf.</Paragraph>
        <Paragraph>To get the key, please follow these steps:</Paragraph>
        <Tabs
          activeKey={currentTab}
          onChange={(key) => setCurrentTab(key)}
          animated={false}
          style={{fontSize: '16px'}}
        >
          <TabPane tab="Instructions for Bittrex" key="bittrex">
            <BittrexInstructions/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};


const BittrexInstructions = () => {
  return <div>
    <Helmet title="Obyte Tading Rewards - How to join with Bittrex account" />
    <Paragraph>1. <a target="_blank" rel="noopener" href="https://global.bittrex.com/account/login?returnUrl=/Market/Index?MarketName=BTC-GBYTE">Log in to your Bittrex account</a>.</Paragraph>
    <Paragraph>2. Click "Account" in the top menu.</Paragraph>
    <img src="/img/bittrex-menu-account.png" alt="Account in Bittrex menu" />
    <Paragraph>3. Click "API Keys" in the left menu.</Paragraph>
    <img src="/img/bittrex-menu-api-keys.png" alt="API Keys in Bittrex menu" />
    <Paragraph>4. Click "Add new key".</Paragraph>
    <img src="/img/bittrex-add-new-key.png" alt="Add new API Key in Bittrex" />
    <Paragraph>5. Toggle "READ INFO" switch on. Important: leave the "TRADE" and "WITHDRAW" switches off, this is what makes the key read-only.</Paragraph>
    <img src="/img/bittrex-key-permissions.png" alt="API Key permissions in Bittrex" />
    <Paragraph>6. Click "Save" button and confirm the operation with your 2FA code.</Paragraph>
    <Paragraph>7. Save your "Key" and "Secret" in a safe place. You'll share them with us when you join and use them to review your rewards later.</Paragraph>
    <img src="/img/bittrex-api-key-secret.png" alt="Bittrex API Key and Secret" />
    <Paragraph>8. <Link to="/me" onClick={() => ReactGA.event({ category: 'join', action: 'howtojoin click join', label: 'bittrex' })}>Join</Link> using your "Key" and "Secret". The "Key" serves as login while the "Secret" serves as password. We'll start tracking your trades immediately after you join and add your address for rewards.</Paragraph>
    <Paragraph>9. If you ever lose your API Secret, you can always generate a new Key/Secret pair as above.</Paragraph>
    <Paragraph>10. You're all set!</Paragraph>
  </div>
}

const BitzInstructions = () => {
  return <div>
    <Helmet title="Obyte Tading Rewards - How to join with Bit-Z account" />
    <Paragraph>1. <a target="_blank" rel="noopener" href="https://u.bitz.so/login">Log in to your Bit-Z account</a>.</Paragraph>
    <Paragraph>2. Click "<a href="https://u.bitz.so/en/ucenter/api/list" target="_blank" rel="noopener">API</a>" in the left menu.</Paragraph>
    <img src="/img/bitz-menu-api.png" alt="API in Bit-Z menu" />
    <Paragraph>3. Enter a name for your new key and click "Create new KEY".</Paragraph>
    <img src="/img/bitz-add-new-key.png" alt="Add new API Key in Bit-Z" />
    <Paragraph>4. Make sure that only "Read-only" checkbox is on. Important: leave the "Withdraw" and "Exchange" checkboxes off, this is what makes the key read-only. Also, whitelist the IP address of our server 46.101.192.92 in order to make the key permanent.</Paragraph>
    <img src="/img/bitz-key-permissions.png" alt="API Key permissions in Bit-Z" />
    <Paragraph>5. Click "OK" button and confirm the operation with your 2FA code.</Paragraph>
    <Paragraph>6. Save your "API Key" and "Secret" in a safe place. You'll share them with us when you join and use them to review your rewards later.</Paragraph>
    <img src="/img/bitz-api-key-secret.png" alt="Bit-Z API Key and Secret" />
    <Paragraph>7. <Link to="/me" onClick={() => ReactGA.event({ category: 'join', action: 'howtojoin click join', label: 'bitz' })}>Join</Link> using your "API Key" and "Secret". The "API Key" serves as login while the "Secret" serves as password. We'll start tracking your trades immediately after you join and add your address for rewards.</Paragraph>
    <Paragraph>8. If you ever lose your API Secret, you can always generate a new Key/Secret pair as above.</Paragraph>
    <Paragraph>9. You're all set!</Paragraph>
  </div>
}
