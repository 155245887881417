import React from "react";
import { Router, Route } from "react-router-dom";
import historyInstance from "./historyInstance";
import {
  HomePage,
  HowToJoinPage,
  FaqPage,
  MainPage,
  Payouts,
} from "./pages";

import { MainLayout } from "./components/MainLayout/MainLayout";

const AppRouter = () => {

  return (
    <Router history={historyInstance}>
      <MainLayout>
        <Route path="/me/:tab?" component={MainPage} />
        <Route path="/how-to-join/:tab?" component={HowToJoinPage} />
        <Route path="/faq" component={FaqPage} />
        <Route path="/payouts" component={Payouts} />

        <Route path="/" component={HomePage} exact />
      </MainLayout>
    </Router>
  );
};

export default AppRouter;
