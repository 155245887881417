import React from "react";
import { Collapse, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet-async";

import styles from "./FaqPage.module.css";

const { Panel } = Collapse;
const { Title } = Typography;

export const FaqPage = () => {
  return (
    <div className="faq">
      <Helmet title="Obyte Trading Rewards - F.A.Q." />
      <Title level={1}>F.A.Q.</Title>
      <Collapse
        accordion
        expandIconPosition="right"
        bordered={false}
        className={styles.collapse}
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} className={styles.icon} />
        )}
      >
        <Panel
          header="I lost my API key/secret, how to restore access?"
          key="0"
          className={styles.panel}
        >
          <p>
            Create a new API key and secret on Bittrex and use them to log in.
          </p>
        </Panel>

        <Panel
          header="How do I leave the rewards programs?"
          key="1"
          className={styles.panel}
        >
          <p>
            Just revoke your API key and secret on Bittrex and we won't be able to see your new trades.
          </p>
        </Panel>

        <Panel
          header="I joined but I don't see my trades, why?"
          key="2"
          className={styles.panel}
        >
          <p>
            Have you entered your Obyte address for rewards? We start collecting data only once we know where to pay the rewards to.
          </p>
        </Panel>

        <Panel
          header="My most recent trades are not shown, why?"
          key="3"
          className={styles.panel}
        >
          <p>
            The data is updated every 10 minutes, check again later.
          </p>
        </Panel>

        <Panel
          header="What are 'period close sell' and 'period open buy' in my trade history?"
          key="4"
          className={styles.panel}
        >
          <p>
            These are special virtual trades we add to close one reward period and start the new one. Nothing is really sold or bought, these operations exist only in our accounting to properly close and open the rewards periods.
          </p>
          <p>
            The 'period close sell' is added at the last moment of the period and closes all your long positions at the price recorded at the end of the period. This makes your unrealized profit/loss realized in our bookkeeping and allows to pay a reward if the positions were closed with profit. The 'period open buy' is added in the first moment of the next period and this virtual trade re-opens the position closed by 'period close sell'. It does so at the same price, so the two trades cancel each other.
          </p>
          <p>
            This closing and re-openening of your long position means that if you had unrealized profit at the end of the period, it is doubled and you get your reward in this period. However, the price has to grow above the period open price for you to get a reward in the next period again. If you had a loss in the previous period, the loss is left in that period and you start the new period with a position opened at a lower price and therefore have higher chances to close it with profit.
          </p>

        </Panel>

        <Panel
          header="How often are the rewards paid?"
          key="5"
          className={styles.panel}
        >
          <p>
            The rewards are paid daily. The rewards for the previous day are sent in the early hours (UTC) of the next day.
          </p>
        </Panel>

        <Panel
          header="When do you start tracking my trades?"
          key="6"
          className={styles.panel}
        >
          <p>
            All trades, deposits, and withdrawals executed after you joined, contribute to your rewards. However, we first request your trade history only after you add your address for payouts. We do so every 10 minutes, so there is some delay before you see your trades. The same applies to deposits and withdrawals.
          </p>
        </Panel>

        <Panel
          header="My limit order was filled in several trades, what would be its execution date?"
          key="7"
          className={styles.panel}
        >
          <p>
            Due to the way Bittrex reports their data, a limit order appears in your trade history under the date when it was fully filled, even if it was filled in several trades. The full order amount will appear under this date. If execution date is important for you, we recommend putting several smaller limit orders.
          </p>
        </Panel>

      </Collapse>
      <div className={styles.action}>
        Any other questions? Ask on <a href="https://discord.obyte.org/" target="_blank" rel="noopener">Discord</a>.
      </div>
    </div>
  );
};
