import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

import styles from "./Rewards.module.css";
import doubleProfit from "../../img/2xprofit.svg";
import shop from "../../img/shop.svg";

export const Rewards = () => {
  const [isShownList, setVisible] = useState({
    reward1: false,
    reward2: false
  });

  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>Rewards</h2>
      <div className={styles.desc}>Every participant is automatically enrolled in both rewards programs and gets weekly rewards from both. </div>
      <div>
        <div className={styles.reward}>
          <div className={styles.info}>
            <h4 className={styles.rewardTitle}>REWARD 1: We double your profit from long positions</h4>
            <p>Whenever you buy GBYTE (open a long position) and then sell (close the position) with profit, we double your profit.</p>
            <p>The rewards are paid once a day and if your position is still open at the end of the day, and it is profitable, we double your unrealized profit too.</p>
            <p>In more detail:</p>
            <ul className={styles.list}>
              <li>Trading GBYTE/BTC pairs is rewarded.</li>
              <li>Profits are accounted for in BTC — the quote currency.</li>
              <li>Rewards are paid in GBYTE using the GBYTE/BTC exchange rate at the end of the day.</li>
              <li>There is a 10 GBYTE cap on the total amounts of rewards per day paid to all users. If it's exceed, all rewards are scaled down so that the total is 10 GBYTE.</li>
              <li>Making profit from short positions (sell high, buy low) is OK but it won't be doubled.</li>
              {isShownList.reward1 &&
                <>
                  <li>If your long position happens to be not profitable, the loss is not doubled obviously, only profitable trades are rewarded.</li>
                  <li>When there are several trades, we use FIFO method to apply them. That means that a sell would close the first long position that is still open (if any) or would open a short position. A buy would close the first short position that is still open (if any) or open a long position.</li>
                  <li>Withdrawals are treated as sells and would close your open long positions or increase your short position.</li>
                  <li>If your position is positive (long) at the end of the daily, we account for the unrealized profit/loss in this day by creating a virtual sell transaction that closes all your long positions at the end of the day at then-current price. Then, a virtual buy transaction is created at the start of the next day at the same price. These two virtual transactions cancel each other but help to close the previous day, pay the reward (if the unrealized profit is positive), and start the new day with a long position opened at the current price.</li>
                  <li>If you already have GBYTE on the exchange and planned to withdraw, please withdraw before you join, as withdrawing when you are already in the program may negatively affect your rewards.</li>
                </>}
            </ul>
            <button className={styles.show} onClick={() => { setVisible((v) => ({ ...v, reward1: !v.reward1 })) }}>{isShownList.reward1 ? "Hide" : "Show more"}</button>
            <Link to="/me" className={styles.btnJoin} onClick={() => ReactGA.event({ category: 'join', action: '2x click join' })}>Join now</Link>
          </div>
          <div className={styles.illustration}>
            <img src={doubleProfit} alt="We double your profit from long positions" />
          </div>
        </div>
        <div className={styles.reward}>
          <div className={styles.illustration}>
            <img src={shop} alt="Buy GBYTE, and get more" />
          </div>
          <div className={styles.info}>
            <h4 className={styles.rewardTitle}>REWARD 2: Buy GBYTE, and get more</h4>
            <p>Buy more GBYTE than you sell, and get a share of 10 GBYTE every day.</p>
            <p>You are rewarded for <b>net buys</b> in GBYTE/BTC pair. Net buys are defined as buys minus sells minus net withdrawals. Net withdrawals are defined as withdrawals minus deposits up to the withdrawal amount, i.e. net withdrawlas are always positive or zero.</p>
            <p>Your share in the total 10 GBYTE reward pool is equal to your share in the total net buys during the day.</p>
            <p>In more detail:</p>
            <ul className={styles.list}>
              <li>If your net buys are negative during a day, you obviously get no reward for that day.</li>
              <li>To get rewards in the subsequent days, you have to first make up for the negative net buys in the previous days (if any). In other words, your net buys are calculated for the period starting from the end of the last day when you had positive net buys.</li>
              <li>If you already have GBYTE on the exchange and planned to withdraw, please withdraw before you join, as withdrawing when you are already in the program may negatively affect your rewards.</li>
            </ul>
            <Link to="/me" className={styles.btnJoin} onClick={() => ReactGA.event({ category: 'join', action: 'netbuy click join' })}>Join now</Link>
          </div>
        </div>
      </div>
    </div>
  );
}