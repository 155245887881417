import React, { useEffect } from "react";
import { Select, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPeriodId } from "../../store/periodsSlice";


export const SelectPeriod = () => {
  const { list, selectedPeriodId } = useSelector((state) => state.periods);
  const dispatch = useDispatch();

  useEffect(() => {
    if (list && !selectedPeriodId) {
      dispatch(setSelectedPeriodId(list[0].period_id));
    }
  });

  const optionList = list && list.map((period, index) =>
    <Select.Option value={period.period_id} key={period.period_id}>
      {period.start_date.substr(0, 10)} to {period.end_date.substr(0, 10)}{!index && " (current)"}
    </Select.Option>
  );


  return (
    <div
      style={{
        background: "#fff",
        marginTop: 10,
        marginBottom: 20,
      }}
    >
      <Row>
        <Select
          size="large"
          optionFilterProp="children"
          placeholder="Please select a period"
          style={{ width: "100%" }}
          showSearch={false}
          value={selectedPeriodId || undefined}
          loading={!list}
          onChange={(period_id) => {
            dispatch(setSelectedPeriodId(period_id));
          }}
        >
          {optionList && optionList.length > 0 && optionList}
        </Select>
      </Row>
    </div>
  );
};
