import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import {
  setAuth,
} from 'store/authSlice';
import './LoginForm.css';
import { Form, Input, Button, Select, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from 'services/api';

const { Text } = Typography;

const exchanges = {
  bittrex: 'Bittrex',
  //bitz: 'Bit-Z',
};

export const LoginForm = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [apiPasswordVisible, setApiPasswordVisibility] = useState(false);

  const onFinish = async (values) => {
    setError(false);
    const resp = await login(values.exchange, values.api_key, values.api_secret);
    setError(resp.status === 'error' ? resp.error : false);
    if (resp.status === 'error')
      return;
    const auth_data = resp.data;
    dispatch(setAuth({ exchange: values.exchange, ...auth_data }));
    ReactGA.event({ category: auth_data.is_new ? 'registration' : 'returning user', action: 'login' });
  };

  const optionList = [];
  for (let exchange in exchanges)
    optionList.push(<Select.Option value={exchange} key={exchange}>{exchanges[exchange]}</Select.Option>);

  return (
    <Form
      name="login"
      className="login-form"
      onFinish={onFinish}
    >
      <Form.Item
        name="exchange"
        dependencies={['api_password']}
        rules={[
          {
            required: true,
            message: 'Please select your exchange!',
          },
        ]}
      >
        <Select
          size="large"
          optionFilterProp="children"
          placeholder="Please select your exchange"
          showSearch={false}
          onChange={(value) => setApiPasswordVisibility(value === 'notneeded')}
        >
          {optionList}
        </Select>
      </Form.Item>
      <Form.Item
        name="api_key"
        rules={[
          {
            required: true,
            message: 'Please input your API key!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="API key" />
      </Form.Item>
      <Form.Item
        name="api_secret"
        rules={[
          {
            required: true,
            message: 'Please input your API secret!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          size="large"
          placeholder="API secret"
        />
      </Form.Item>
      {apiPasswordVisible && <Form.Item
        name="api_password"
        rules={[
          {
            required: true,
            message: 'Please input your API password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          size="large"
          placeholder="API password"
        />
      </Form.Item>}

      <Form.Item>
        <Link to="/how-to-join">
          Where to get API key and secret?
        </Link>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" size="large">
          Log in
        </Button>
      </Form.Item>

      {error && (
        <div style={{ textAlign: "center" }}>
          <Text type="secondary" style={{ fontSize: 12, color: "red" }}>
            {error}
          </Text>
        </div>
      )}

    </Form>
  );
};

