import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setPeriods } from 'store/periodsSlice';
import { Layout, Drawer, Row, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import { useWindowSize } from "hooks/useWindowSize";
import styles from "./MainLayout.module.css";
import { MainMenu } from "../MainMenu/MainMenu";
import { SocialIcons } from "../SocialIcons/SocialIcons";
import { getInfo } from "services/api";
import historyInstance from "../../historyInstance";
import { selectAuth, resetAuth } from 'store/authSlice';

const { Header, Content, Footer } = Layout;

export const MainLayout = ({ children }) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [width] = useWindowSize();
  const [activeMenu, setActiveMenu] = useState(false);
  let [counter, setCounter] = useState(0);

  useEffect(() => {
    const updateInfo = async () => {
      const resp = await getInfo();
      if (resp.status === 'success') {
        dispatch(setPeriods(resp.data));
      }
    };
    updateInfo();
  }, [counter]);

  useEffect(() => {
    setInterval(() => setCounter(counter + 1), 10 * 60 * 1000);
  }, []);

  useEffect(() => {
    const unlisten = historyInstance.listen((location, action) => {
      if (action === "PUSH" || action === "POP") {
        ReactGA.pageview(location.pathname);
      }
    });
    ReactGA.pageview(pathname);
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div style={{ background: "#101827", color: "#fff", padding: 10, fontWeight: "bold", textAlign: "center" }}>The rewards program is not active anymore since Bittrex has shut down</div>
      <Header
        style={{
          background: "#fff",
          paddingLeft: 20,
          paddingRight: 20,
          height: "100%"
        }}
      >
        <Row
          justify={width < 990 ? "space-between" : undefined}
          align="middle"
        >
          <NavLink to="/" className={styles.navLink}>
            <img className={styles.logo} src="/logo256.png" alt="Obyte Trading Rewards" />

            {width > 440 && <div style={{ paddingLeft: 10 }}>
              <span>Obyte Trading Rewards</span>
            </div>}
          </NavLink>

          {width >= 990 ? (
            <MainMenu pathname={pathname} width={width} mode="horizontal" />
          ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Drawer
                  title={
                    <span>
                      Obyte Trading Rewards
                  </span>
                  }
                  placement="left"
                  closable={true}
                  onClose={() => setActiveMenu(false)}
                  visible={activeMenu}
                  bodyStyle={{ padding: 0, overflowX: "hidden" }}
                >
                  <MainMenu
                    pathname={pathname}
                    onClose={() => setActiveMenu(false)}
                    mode="vertical"
                  />
                  <div style={{ paddingLeft: 7 }}><SocialIcons size="short" /></div>
                </Drawer>

                <div style={{ marginLeft: "auto", marginRight: 20 }}><Button onClick={() => setActiveMenu(true)}>Menu</Button></div>
                <div style={{ marginLeft: "auto" }}><JoinLogOff /></div>
              </div>
            )}

          {width >= 990 && <div style={{ marginLeft: "auto" }}><JoinLogOff /></div>}

        </Row>
      </Header>

      <Content
        className={styles.content}
        style={
          pathname === "/" || width < 1240
            ? { padding: 0 }
            : { padding: "20px 20px" }
        }
      >
        {children !== undefined && children !== null && (
          <div style={{ background: "#fff", padding: 20 }}>
            {children}
          </div>
        )}
      </Content>
      <Footer>
        <SocialIcons centered />
      </Footer>
    </Layout>
  );
};

const JoinLogOff = () => {
  let auth = useSelector(selectAuth);
  const dispatch = useDispatch();

  return (auth && auth.id)
    ? <Button type="link" onClick={() => dispatch(resetAuth())}>Log off</Button>
    : <Button type="primary" style={{ borderRadius: 5 }} onClick={() => {
      ReactGA.event({ category: 'join', action: 'menu click join' });
      historyInstance.push("/me");
    }}>JOIN</Button>;
};
