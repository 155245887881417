import React from "react";
import { Helmet } from "react-helmet-async";

import styles from "./HomePage.module.css";
import { Header } from "./components/Header/Header";
import { WhereToBegin } from "./components/WhereToBegin/WhereToBegin";
import { Rewards } from "./components/Rewards/Rewards";




export const HomePage = () => {
  return (<div>
    <Helmet title="Obyte Trading Rewards" />
    <Header />
    <div className={styles.container}>
      <WhereToBegin />
      <Rewards />
    </div>
  </div>)
};
