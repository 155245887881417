import React from "react";
import { useSelector } from 'react-redux';
import { Table, Tooltip, Typography } from "antd";
import { selectPeriods } from "store/periodsSlice";
import { Label } from "components/Label/Label";
import { round } from "utils";


const { Title } = Typography;

export const Trades = ({ trades, unrealized_long_profit }) => {

  let { selectedPeriodId, list } = useSelector(selectPeriods);
  if (!list || !selectedPeriodId)
    return null;
  const period = list.find(p => p.period_id === selectedPeriodId);
  const filtered_trades = trades.filter(t => t.trade_date >= period.start_date && t.trade_date < period.end_date);

  const total_buys = filtered_trades.filter(t => t.side === 'buy' && t.type === 'trade').reduce((s, t) => s + t.amount, 0);
  const total_sells = filtered_trades.filter(t => t.side === 'sell' && t.type === 'trade').reduce((s, t) => s + t.amount, 0);

  const realized_long_profit = filtered_trades.filter(t => t.side === 'sell' && t.profit > 0).reduce((s, t) => s + t.profit, 0);
  const bOngoingPeriod = selectedPeriodId === list[0].period_id;

  const columns = [
    {
      title: "UTC Date",
      dataIndex: "trade_date",
      key: "ts",
      showSorterTooltip: true,
      sorter: (t1, t2) => t1.trade_date < t2.trade_date ? -1 : 1,
      render: (value) => {
        return value;
      },
    },
    {
      title: "Type",
      dataIndex: "side",
      key: "side",
      render: (value, trade) => {
        return <span style={{ color: value === 'buy' ? 'green' : 'red' }}>{trade.type === 'trade' ? value : trade.type + ' ' + value}</span>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => {
        return value;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => {
        return value.toFixed(8);
      },
    },
    {
      title: <Label label="Remaining amount" descr="Part of this trade that is not closed yet by an opposite trade" />,
      dataIndex: "remaining_amount",
      key: "remaining_amount",
    },
    {
      title: <Label label="Position" descr="Your total position after a trade: positive numbers mean long position, negative - short position" />,
      dataIndex: "position",
      key: "position",
    },
    {
      title: <Label label="Profit" descr="Profit from closing the last open trade of the opposite side" />,
      dataIndex: "profit",
      key: "profit",
      render: (value, trade) => {
        return value > 0 && trade.side === 'sell' ? <Tooltip title="This profit qualifies for doubling"><span style={{ color: "green" }}>{value.toFixed(8)}</span></Tooltip> : value.toFixed(8);
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Title level={3}>Trades</Title>
      </div>


      <Table
        dataSource={filtered_trades}
        rowKey="trade_id"
        columns={columns}
        // onRow={(record) => {
        //   if (record.type === 'withdrawal')
        //     return {
        //       side: 'withdrawal'
        //     }
        // }}
        locale={{
          emptyText: "No trades",
        }}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
      />

      <div style={{ marginTop: '20px' }}>
        <div>Total buys: {round(total_buys)} GBYTE.</div>
        <div>Total sells: {round(total_sells)} GBYTE.</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        {!bOngoingPeriod && <div>Total profit from long trades: {round(realized_long_profit, 8)} BTC.</div>}
        {bOngoingPeriod && <div>
          <div>Realized profit from long trades: {round(realized_long_profit, 8)} BTC.</div>
          <div>Unrealized profit from long trades: {round(unrealized_long_profit || 0, 8)} BTC.</div>
          <div>Total estimation of profit from long trades: {round(realized_long_profit + Math.max(unrealized_long_profit, 0), 8)} BTC.</div>
        </div>}
      </div>
    </>
  );
};
