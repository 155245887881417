import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

import firstStep from "../../img/first-step.svg";
import styles from "./WhereToBegin.module.css";

export const WhereToBegin = () => {
  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>How to start?</h2>
      <div className={styles.container}>
        <div className={styles.illustration}><img src={firstStep} alt="" /></div>
        <div className={styles.steps}>
          <p>In order to start receiving rewards, you need to do 3 simple steps:</p>
          <div className={styles.step}>
            <div className={styles.number}>1</div>
            <span>Register on <a target="_blank" rel="noopener" href="https://global.bittrex.com/Market/Index?MarketName=BTC-GBYTE">Bittrex</a></span>
          </div>
          <div className={styles.step}>
            <div className={styles.number}>2</div>
            <span>Get your API Key and Secret with read-only access (<Link to="/how-to-join">how?</Link>)</span>
          </div>
          <div className={styles.step}>
            <div className={styles.number}>3</div>
            <span><Link to="/me" onClick={() => ReactGA.event({ category: 'join', action: 'howtostart click join' })}>Join the rewards program</Link> with your API Key and Secret</span>
          </div>
        </div>
      </div>
    </div>
  );
}