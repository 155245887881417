import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

export const MainMenu = ({ mode, pathname, onClose, width }) => {
  
  const getPathnameForMenu = () => {
    if (pathname.startsWith("/me"))
      return "/me";
    if (pathname.startsWith("/how-to-join"))
      return "/how-to-join";
    return pathname;
  };

  return (
    <Menu
      mode={mode === "horizontal" ? "horizontal" : "vertical"}
      breakpoint="lg"
      overflowedIndicator=". . ."
      collapsedWidth="0"
      style={{ border: "none", width: (width < 1340 && mode === "horizontal" ? width - 40 - 205 - 250 - 70 - 30 : 760) }}
      selectedKeys={pathname !== "/" ? [getPathnameForMenu()] : []}
      onOpenChange={() => {
        onClose && onClose();
      }}
    >
      <Menu.Item key="/me">
        <NavLink to="/me" activeClassName="selected" style={{ verticalAlign: "middle" }}>
          My rewards
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/how-to-join">
        <NavLink to="/how-to-join" activeClassName="selected" style={{ verticalAlign: "middle" }}>
          How to join
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/payouts">
        <NavLink to="/payouts" activeClassName="selected" style={{ verticalAlign: "middle" }}>
          Payouts
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/faq">
        <NavLink to="/faq" activeClassName="selected" style={{ verticalAlign: "middle" }}>
          F.A.Q.
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};
