import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Input, Modal, Typography } from "antd";
import obyte from "obyte";

const { Text } = Typography;

export const AddWalletAddressModal = ({ visible, initialAddress, title, hide, saveAddress }) => {
  const addressInput = useRef(null);
  const [address, setAddress] = useState({
    value: initialAddress,
    valid: initialAddress ? true : undefined,
  });

  useEffect(() => {
    setAddress({
      value: initialAddress,
      valid: initialAddress ? true : undefined,
    });
  }, [initialAddress]);

  const handleChange = (ev) => {
    const value = ev.target.value;
    setAddress({ value: value, valid: obyte.utils.isValidAddress(value) });
  };

  let validateStatus = "";
  if (address.valid === true) {
    validateStatus = "success";
  } else if (address.valid === false) {
    validateStatus = "error";
  } else {
    validateStatus = "";
  }

  const handleCancel = () => {
    hide();
    //setAddress({ value: undefined, valid: undefined });
  };

  const handleSave = () => {
    saveAddress(address.value);
    hide();
  };

  useEffect(() => {
    if (addressInput.current) {
      addressInput.current.focus();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={title}
      style={{ zIndex: -1 }}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          key="save"
          type="primary"
          disabled={!address.valid || address.value === initialAddress}
          onClick={() => handleSave()}
        >
          Save
        </Button>,
      ]}
    >
      <Form size="large">
        <Form.Item hasFeedback={true} validateStatus={validateStatus}
          extra={
            <p>
              <Text type="secondary">
                Insert your Obyte wallet address. If you don't have Obyte wallet yet,{" "}
                <a href="https://obyte.org/#download" target="_blank" rel="noopener">install</a> it.
              </Text>
            </p>
          }>
          <Input
            placeholder="Wallet address"
            value={address.value}
            defaultValue={address.value}
            onChange={handleChange}
            ref={addressInput}
            autoFocus={true}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleSave();
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
