import { createSlice } from '@reduxjs/toolkit';

export const periodsSlice = createSlice({
  name: 'periods',
  initialState: {},
  reducers: {
    setPeriods: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.list = action.payload;
		  //return action.payload;
    },
    setSelectedPeriodId: (state, action) => {
      state.selectedPeriodId = action.payload;
    }
  },
});

export const { setPeriods, setSelectedPeriodId } = periodsSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.auth.value)`
export const selectPeriods = state => state.periods;

export default periodsSlice.reducer;
