import React from "react";
import { useSelector } from 'react-redux';
import { Card, List, Table, Typography } from "antd";
import { selectPeriods } from "store/periodsSlice";
import { round } from "utils";
import { useWindowSize } from "hooks/useWindowSize";

const { Title } = Typography;

export const Payouts = () => {
  let { list } = useSelector(selectPeriods);
  const [width] = useWindowSize();
  const columns = [
    {
      title: "Dates",
      dataIndex: "start_date",
      key: "start_date",
      showSorterTooltip: true,
      render: (value, period, index) => {
        return value.substr(0, 10) + " to " + period.end_date.substr(0, 10) + (index ? '' : " (estimate)");
      },
    },
    {
      title: "Long Profits",
      dataIndex: "total_long_profits",
      key: "total_long_profits",
      render: (value) => {
        return round(value, 8) + " BTC";
      },
    },
    {
      title: "Net Buys",
      dataIndex: "total_net_buys",
      key: "total_net_buys",
      render: (value) => {
        return round(value) + " GBYTE";
      },
    },
    {
      title: "Paid for Long Profits",
      dataIndex: "long_profits_scaling_factor",
      key: "long_profits_scaling_factor",
      render: (value, period) => {
        if (!value)
          return 0;
        const percentage = round(value * 100, 2);
        const btc_value = value * period.total_long_profits;
        const gb_value = btc_value / period.close_price;
        return <span>{round(gb_value)} GBYTE ({round(btc_value, 8)} BTC)<br />+{percentage}% of profit</span>;
      },
    },
    {
      title: "Paid for Net Buys",
      dataIndex: "net_buys_scaling_factor",
      key: "net_buys_scaling_factor",
      render: (value, period) => {
        if (!value)
          return 0;
        const percentage = round(period.net_buys_scaling_factor * 100, 2);
        return <span>{round(period.total_net_buys * period.net_buys_scaling_factor, 2)} GBYTE<br />+{percentage}% of net bought</span>;
      },
    },

  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Title level={1}>Paid rewards</Title>
      </div>

      {width >= 1024 ? <Table
        dataSource={list || []}
        rowKey="period_id"
        columns={columns}
        locale={{
          emptyText: "No payouts yet",
        }}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
      /> : <List
          itemLayout="horizontal"
          dataSource={list || []}
          locale={{
            emptyText: "No payouts yet",
          }}
          renderItem={(item, index) => {
            return (
              <Card style={{ marginBottom: 20 }}>
                <div><b>Dates: </b>{item.start_date.substr(0, 10)} to {item.end_date.substr(0, 10) + (index ? '' : " (estimate)")}</div>
                <div><b>Long Profits: </b>{round(item.total_long_profits, 8)} BTC</div>
                <div><b>Net Buys: </b>{round(item.total_net_buys)} GBYTE</div>
                <div><b>Paid for Long Profits: </b>{item.long_profits_scaling_factor ? <span>{round((item.long_profits_scaling_factor * item.total_long_profits) / item.close_price)} GBYTE ({round(item.long_profits_scaling_factor * item.total_long_profits, 8)} BTC) +{round(item.long_profits_scaling_factor * 100, 2)}% of profit</span> : 0}</div>
                <div><b>Paid for Net Buys: </b>{item.net_buys_scaling_factor ? <span>{round(item.total_net_buys * item.net_buys_scaling_factor, 2)} GBYTE +{round(item.net_buys_scaling_factor * 100, 2)}% of net bought</span> : 0}</div>
              </Card>
            )
          }}
        />}
    </>
  );
};
